import { motion } from "framer-motion"

const logoVariants = {
    hidden: {
        rotate: "160deg",
    },
    show: {
        rotate: 0,
        transition: {
            // duration: 1,
            delay: 0.5,
            bounce: 0,
            mass: 30,
            damping: 100,
            type: "spring",
            restDelta: 0.001,
            restSpeed: 0.001,
            staggerChildren: 0.1,
        },
    },
}

const itemVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 1 } },
}

export default function CircleLogo() {
    return (
        <motion.svg
            style={{ originX: "50%", originY: "50%" }}
            variants={logoVariants}
            shapeRendering="geometricPrecision"
            width="111"
            className="origin-center"
            height="111"
            viewBox="0 0 111 111"
            fill="none"
            initial="hidden"
            whileInView="show"
            viewport={{ margin: "-200px", once: true }}
            xmlns="http://www.w3.org/2000/svg">
            {/* Each <path> is a letter */}
            <motion.path
                variants={itemVariants}
                d="M46.677 94.1541L46.0559 95.7302L41.2651 95.0374L42.4728 91.9892C42.6626 91.5158 42.5016 91.1463 42.1105 90.9904L40.9833 90.5401C40.5922 90.3843 40.2356 90.517 39.994 91.1175L39.0278 93.5653C38.7805 94.1945 38.861 94.6852 39.5627 95.2106L42.0127 97.0695C43.393 98.1029 44.4628 99.9965 42.9272 103.888C41.2536 108.119 38.1076 108.633 34.7316 107.288C31.6144 106.047 30.027 103.616 31.3268 100.331L31.7754 99.1998L36.5663 99.8926L35.5368 102.496C35.3297 103.022 35.5138 103.403 35.9049 103.559L37.0551 104.015C37.4232 104.159 37.774 103.968 37.9811 103.437L38.8438 101.255C39.1888 100.389 38.9128 100.002 38.2801 99.5404L35.9336 97.8143C33.9897 96.371 33.4376 94.385 34.8696 90.7595C36.2327 87.313 39.2003 85.633 42.8927 87.1051C46.2457 88.4387 47.8791 91.129 46.6885 94.1541H46.677Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M22.4477 100.422L17.1565 95.5788C14.8848 93.5005 14.8963 90.7179 18.2608 87.0173C21.7173 83.2128 24.3054 83.132 26.5772 85.2103L28.4693 86.948L33.2947 81.6367L36.6879 84.7427L22.4477 100.422ZM24.5469 88.4086C24.2536 88.143 23.8338 88.143 23.552 88.4548L20.0954 92.2593C19.8309 92.5537 19.8309 92.9751 20.1415 93.258L21.5563 94.557L25.9618 89.7076L24.5469 88.4086Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M25.5008 79.5435L24.1493 77.3208L12.6927 84.3236L14.0385 86.5463L10.743 88.5611L5.6416 80.1496L8.93709 78.1348L10.2829 80.3575L21.7395 73.3547L20.3937 71.132L23.6892 69.1172L28.7906 77.5286L25.4951 79.5435H25.5008Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M2.58764 72.3956L1.53516 67.8753L18.2714 63.938L17.2707 59.6428L21.1126 58.7422L23.1715 67.5578L2.58764 72.3956Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M0 55.4147L0.362332 50.7904L17.5012 52.1413L17.8463 47.7422L21.7802 48.0539L21.0727 57.0773L0 55.4147Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M2.66895 38.2232L6.75237 28.7148L10.3757 30.2794L8.12117 35.5271L12.2391 37.3053L14.3326 32.4212L17.6971 33.876L15.6036 38.7601L20.2909 40.7865L22.5454 35.5387L26.1687 37.1032L22.0853 46.6115L2.66895 38.2232Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M11.6405 21.177L16.2358 16.4315C18.7319 13.8567 20.8944 13.2447 24.2474 16.5181C27.1575 19.3585 26.151 21.6908 24.5867 23.6768C24.4487 23.8558 24.6327 24.0347 24.811 23.8962L26.8009 22.4471C27.2323 22.1238 27.7959 22.1527 28.1755 22.4067L35.4336 27.0483L32.1324 30.4545L25.2308 25.6916C25.0468 25.5531 24.8282 25.5762 24.673 25.7378L23.8275 26.6095L30.0102 32.6425L26.7894 35.9678L11.6348 21.177H11.6405ZM22.7635 22.8801C23.0396 22.5972 23.0511 22.1758 22.752 21.8814L19.9454 19.1391C19.6808 18.8793 19.2437 18.8505 18.9504 19.1507L17.6161 20.5304L21.435 24.2599L22.7693 22.8801H22.7635Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M27.583 7.37911L36.7161 3.07812L38.3552 6.58819L33.4033 8.92053L35.4738 13.3485L40.0173 11.2067L41.5874 14.5609L37.0439 16.7027L40.742 24.6177L36.5608 26.5863L27.583 7.37911Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M42.6689 12.0768C41.3576 3.06496 43.497 0.951995 47.3447 0.386228C51.1348 -0.167992 53.8206 1.24065 55.1319 10.2525C56.449 19.2932 54.275 21.3831 50.4791 21.9431C46.6315 22.5089 43.9801 21.1233 42.6631 12.0826L42.6689 12.0768ZM49.2138 18.1213L50.6056 17.9192C50.968 17.8673 51.2613 17.5382 51.2038 17.1456L49.4094 4.80267C49.3519 4.4101 49.001 4.15031 48.6099 4.20227L47.2181 4.40433C46.7983 4.46783 46.5395 4.81999 46.597 5.21257L48.3914 17.5555C48.4489 17.9481 48.8227 18.179 49.2138 18.1213Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M60.6532 0L67.1177 1.32205C70.6259 2.03792 72.3053 3.53893 71.3736 8.14012C70.5627 12.1293 68.0609 12.545 65.5533 12.291C65.329 12.2737 65.2772 12.5219 65.49 12.597L67.7791 13.4976C68.2852 13.6881 68.5612 14.1788 68.5555 14.6407L68.5842 23.2773L63.9429 22.3305L64.2133 13.9248C64.2305 13.6996 64.0925 13.5264 63.8739 13.4803L62.6892 13.2378L60.9695 21.7185L56.4375 20.7948L60.6532 0ZM65.2312 10.3224C65.6166 10.4032 65.9789 10.1838 66.0594 9.76814L66.8416 5.91746C66.9164 5.55953 66.7036 5.16695 66.2895 5.08036L64.4088 4.69356L63.3448 9.93556L65.2255 10.3224H65.2312Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M80.8859 6.05469L89.5819 11.62L87.4597 14.9569L82.6631 11.8856L80.2533 15.6785L84.722 18.5362L82.7494 21.6364L78.2806 18.7787L75.5372 23.097L80.3338 26.1683L78.2116 29.5051L69.5156 23.9398L80.8802 6.05469H80.8859Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M96.2825 17.707L98.4335 20.6456L91.6182 30.9679C91.5089 31.1527 91.624 31.3143 91.831 31.1642L101.119 24.3173L103.673 27.8042L86.6433 40.3608L84.3428 37.2144L90.0999 28.0236C90.2034 27.8793 90.0309 27.6888 89.8468 27.8216L81.8065 33.7506L79.2529 30.2636L96.2825 17.707Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M90.6694 40.4966L91.3653 43.0021L104.283 39.3708L103.587 36.8652L107.302 35.8203L109.948 45.2998L106.232 46.3447L105.537 43.8392L92.6191 47.4705L93.315 49.976L89.5997 51.021L86.9541 41.5415L90.6694 40.4966Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M111 54.0786L110.69 57.7156L98.7845 60.9832C98.5832 61.0525 98.5659 61.2488 98.819 61.2719L110.304 62.2533L109.936 66.5658L88.875 64.7646L89.2028 60.8735L99.5667 57.756C99.7392 57.7156 99.7334 57.4558 99.5091 57.4385L89.5709 56.5899L89.939 52.2773L111 54.0786Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M98.4448 69.5262C106.537 73.5789 107.037 76.5752 105.323 80.0159C103.742 83.2085 101.533 84.5998 96.3801 82.4291L97.4844 77.672L100.561 79.2135C100.912 79.3924 101.343 79.225 101.522 78.8729L102.126 77.6547C102.315 77.2737 102.177 76.858 101.821 76.6791L90.6231 71.0734C90.2722 70.9002 89.8697 71.0734 89.6914 71.4313L89.0127 72.7995C88.8517 73.1286 88.9379 73.5847 89.2888 73.7579L93.1249 75.6745L93.9301 74.0523L97.1335 75.6572L93.6137 82.7466L90.6116 81.2456L91.2385 79.9813L90.3815 79.5541C84.3772 76.5521 84.3139 73.5154 85.9128 70.2998C87.6209 66.859 90.278 65.4388 98.4506 69.5262H98.4448Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M97.611 91.7049L90.2321 98.9444L87.4715 96.1098L91.5434 92.1148L88.4089 88.8992L84.6188 92.6171L82.0595 89.9845L85.8496 86.2666L82.2838 82.6065L78.2119 86.6015L75.457 83.7669L82.8359 76.5273L97.611 91.6991V91.7049Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M82.8071 104.123L79.4656 105.554L71.0112 96.5367C70.8559 96.3866 70.6777 96.4674 70.7754 96.6983L75.2959 107.338L71.3275 109.035L63.0342 89.5223L66.6115 87.9924L74.2147 95.7169C74.3355 95.8497 74.554 95.7227 74.4678 95.5148L70.5569 86.3067L74.5253 84.6094L82.8186 104.123H82.8071Z"
                fill="white"
            />
            {/* 1977 */}
            <motion.path
                variants={itemVariants}
                d="M50.8301 91.4229L49.8812 93.0047L48.6504 91.2612L50.8416 89.7891H53.0099V97.323H54.5512V99.0549H49.2485V97.323H50.9969V91.4517C50.9969 91.3305 50.8991 91.3016 50.8359 91.4171L50.8301 91.4229Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M61.9073 94.269C61.9073 98.3737 60.8146 99.1761 59.1237 99.1761C57.4328 99.1761 56.5414 98.3852 56.5414 97.0747V96.0817L58.5486 95.718V97.1325C58.5486 97.2941 58.6693 97.4442 58.8591 97.4442H59.4745C59.6586 97.4442 59.7851 97.2941 59.7851 97.1325V94.8347C59.7851 94.7712 59.7218 94.7366 59.6643 94.7712C59.2215 95.0426 58.5543 95.337 57.9562 95.337C57.059 95.337 56.4033 94.7828 56.4033 92.7449C56.4033 90.4068 57.4961 89.6562 59.1467 89.6562C60.8203 89.6562 61.9131 90.4356 61.9131 94.2632L61.9073 94.269ZM58.8304 93.911H59.7908V91.6999C59.7908 91.5267 59.6528 91.394 59.4803 91.394H58.8304C58.6463 91.394 58.5198 91.5325 58.5198 91.6999V93.6051C58.5198 93.7783 58.6406 93.911 58.8304 93.911Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M53.1652 103.597C53.2285 103.534 53.1767 103.459 53.1135 103.459H49.2773V101.727H54.58V103.308C53.0847 105.745 52.4463 108.25 52.4463 110.992H50.1285C50.1918 108.054 51.2673 105.768 53.1652 103.591V103.597Z"
                fill="white"
            />
            <motion.path
                variants={itemVariants}
                d="M60.3889 103.597C60.4521 103.534 60.4004 103.459 60.3371 103.459H56.501V101.727H61.8037V103.308C60.3083 105.745 59.6699 108.25 59.6699 110.992H57.3522C57.4154 108.054 58.4909 105.768 60.3889 103.591V103.597Z"
                fill="white"
            />
        </motion.svg>
    )
}
