import Link from "next/link"
import { path, classNames } from "../lib/helpers"

const NavLink = ({ item, isActive, className, onMouseLeave = () => {} }) =>
    item.linkTo ? (
        <Link
            href={path(item.linkTo.slug.current)}
            key={item.linkTo.slug.current}>
            <a
                onMouseLeave={onMouseLeave}
                className={classNames(
                    "tracking-wider hover:opacity-60 mix-blend-difference inline-block w-full lg:w-auto pointer-events-auto whitespace-nowrap",
                    {
                        underline: isActive,
                        ...className,
                    }
                )}>
                {item.label}
            </a>
        </Link>
    ) : null

export default NavLink
